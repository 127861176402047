import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

export default function PaymentComplete(props) {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
      <Typography variant="h5" className="container-title">
        {t("dashboard.funds.title")}
      </Typography>
      <Typography variant="h5" className="container-title">
        {t("dashboard.funds.payment-complete")}
      </Typography>
    </Box>
  );
}
