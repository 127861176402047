import * as React from "react";
import { useEffect } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useTranslation } from "react-i18next";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function How() {
  const [steps, setSteps] = React.useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("common");

  useEffect(() => {
    setSteps(t("landing.how.steps", { returnObjects: true }));
  }, []);

  const renderIcon = (index) => {
    switch (index) {
      case 0:
        return <AccountCircleIcon className="step-icon" />;
      case 1:
        return <CreditCardIcon className="step-icon" />;
      case 2:
        return <AttachMoneyIcon className="step-icon" />;
      case 3:
        return <CheckCircleIcon className="step-icon" />;
    }
  };

  return (
    <Container
      component="main"
      id="como-funciona"
      sx={{ minHeight: "70vh", paddingTop: "120px", paddingBottom: "60px" }}
    >
      <Typography
        sx={{ fontSize: 45, textAlign: "center" }}
        color="text.primary"
        gutterBottom
      >
        {t("landing.how.title")}
      </Typography>
      <Typography
        color="text.secondary"
        gutterBottom
        sx={{ textAlign: "center" }}
      >
        {t("landing.how.subtitle")}
      </Typography>
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: isMobile ? 200 : 1200,
          marginTop: "10%",
          marginBottom: "10%",
          marginLeft: isMobile ? "auto" : "",
          marginRight: isMobile ? "auto" : "",
        }}
      >
        {steps.map((step, index) => (
          <Grid item xs={12} md={3} key={index}>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              animateOut="animate__fadeOutDown"
              delay={isMobile ? 0 : index * 1000}
            >
              <Box className="how-step">
                <div>
                  <Box sx={{ mb: 2 }}>
                    {renderIcon(index)}
                    <Typography variant="h6">{step.label}</Typography>
                    <Typography>{step.description}</Typography>
                  </Box>
                </div>
              </Box>
            </ScrollAnimation>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
