import * as React from "react";

import Button from "@mui/material/Button";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function Tutorial(props) {
  const { token } = useAuth();

  const close = () => {
    props.close();
  };

  const start = () => {
    props.start();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>Bienvenido a CryptoEnPesos!</DialogTitle>
        <DialogContent>
          <h5>Para empezar, nos gustaría enseñarte a usar la plataforma.</h5>
          <Button onClick={start}>Iniciar tutorial</Button>
          <Button onClick={close}>Conozco la plataforma</Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
