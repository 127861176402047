import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

import styled from "@emotion/styled";
import ReactFileReader from "react-file-reader";
import { enqueueSnackbar } from "notistack";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import { useTranslation } from "react-i18next";

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }
  }
`;

export default function Profile() {
  const { token } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [account, setAccount] = React.useState({});
  const { t } = useTranslation("common");

  useEffect(() => {
    getAccount();
  }, []);

  const getAccount = async () => {
    let response = await endpointRequest("GET", "/account", {}, token);
    setAccount(response.data.account);
  };

  const updateValue = (field, value) => {
    setAccount((oldAccount) => ({
      ...oldAccount,
      [field]: value,
    }));
  };

  const updateProfile = async (event) => {
    event.preventDefault();
    const data = {
      account: account,
    };
    let response = await endpointRequest("PUT", "/account", data, token);
    if (response.data && response.data.result === true) {
      enqueueSnackbar("Perfil actualizado exitosamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al actualizar perfil", {
        variant: "error",
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h3" className="container-title">
        {t("dashboard.profile.title")}
      </Typography>
      <Card
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          display: "flex",
          justifyContent: "center",
          width: !isMobile ? "50%" : "90%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box>
          <CardContent>
            <FormControl fullWidth>
              <>
                <AvatarInput>
                  <img
                    src={account.image ?? "/assets/user.png"}
                    alt="Profile Image"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 5,
                      alignContent: "center",
                      zIndex: 900,
                      right: "4%",
                      border: "1px solid",
                      borderRadius: "50%",
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <ReactFileReader
                      fileTypes={[".png", ".jpg"]}
                      base64={true}
                      handleFiles={(files) =>
                        updateValue("image", files.base64)
                      }
                    >
                      <IconButton>
                        <EditIcon style={{ width: 30, height: 30 }} />
                      </IconButton>
                    </ReactFileReader>
                  </Box>
                </AvatarInput>
              </>
              <TextField
                required
                fullWidth
                id="to"
                label={t("dashboard.profile.name")}
                name="to"
                autoComplete="family-name"
                className="profile-field"
                value={account.name}
                onChange={(e) => updateValue("name", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="to"
                label={t("dashboard.profile.last-name")}
                name="to"
                autoComplete="family-name"
                className="profile-field"
                value={account.lastName}
                onChange={(e) => updateValue("lastName", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="to"
                label="Email"
                name="to"
                autoComplete="email"
                className="profile-field"
                value={account.email}
                onChange={(e) => updateValue("email", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                fullWidth
                id="to"
                label={t("dashboard.profile.last-name")}
                name="to"
                autoComplete="account-phone"
                className="profile-field"
                value={account.phone}
                onChange={(e) => updateValue("phone", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <Button variant="contained" onClick={updateProfile}>
                {t("dashboard.profile.submit")}
              </Button>
            </FormControl>
          </CardContent>
        </Box>
      </Card>
    </Container>
  );
}
