import * as React from "react";
import { useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Backdrop from "@mui/material/Backdrop";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

const LoginInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgb(94, 65, 179)",
  },
  "& .MuiOutlinedInput-input:autofill": {
    "-webkit-box-shadow": "none",
  },
});

const PasswordInput = styled(OutlinedInput)({
  "&": {
    backgroundColor: "rgb(94, 65, 179)",
  },
  "& .MuiOutlinedInput-input:autofill": {
    "-webkit-box-shadow": "none",
  },
});

export default function Login() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const ref = useRef(null);

  const { login } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("common");

  useEffect(() => {
    window.__openLogin = function () {
      setAnchorEl(ref.current);
      setOpen(true);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    Array.from(formData.entries()).forEach(([key, value]) => {
      data[key] = value;
    });
    let response = await endpointRequest("POST", "/login", data);
    try {
      login(response.data.idToken);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "inline-flex" }}>
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            color="inherit"
            ref={ref}
          >
            {t("landing.login-button")}
          </Button>

          <Backdrop open={open} invisible={!isMobile}>
            <Popover
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                top: { xs: "30%", md: "1%" },
              }}
              PaperProps={{
                style: {
                  backgroundColor: "#5823B0",
                  backgroundImage: "none",
                  boxShadow: "none",
                  borderRadius: 14,
                  overflow: "visible",
                },
              }}
            >
              <Box
                sx={{
                  padding: "30px",
                  position: "relative",
                  mt: "10px",
                }}
              >
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <LoginInput
                        autoComplete="username"
                        required
                        fullWidth
                        id="username"
                        label={t("register.user")}
                        name="username"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl variant="standard" fullWidth>
                        <PasswordInput
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          label={t("register.password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <InputLabel
                          htmlFor="password"
                          sx={{
                            top: "-8px",
                            left: "15px",
                          }}
                        >
                          {t("register.password")}
                        </InputLabel>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="gradient-button"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t("landing.login-button")}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Backdrop>
        </div>
      </Box>
    </React.Fragment>
  );
}
