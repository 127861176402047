export const states = [
  { clave: "AGS", name: "Aguascalientes" },
  { clave: "BC", name: "Baja California" },
  { clave: "BCS", name: "Baja California Sur" },
  { clave: "CHI", name: "Chihuahua" },
  { clave: "CHS", name: "Chiapas" },
  { clave: "CMP", name: "Campeche" },
  { clave: "CMX", name: "Ciudad de Mexico" },
  { clave: "COA", name: "Coahuila" },
  { clave: "COL", name: "Colima" },
  { clave: "DGO", name: "Durango" },
  { clave: "GRO", name: "Guerrero" },
  { clave: "GTO", name: "Guanajuato" },
  { clave: "HGO", name: "Hidalgo" },
  { clave: "JAL", name: "Jalisco" },
  { clave: "MCH", name: "Michoacan" },
  { clave: "MEX", name: "Estado de mexico" },
  { clave: "MOR", name: "Morelos" },
  { clave: "NAY", name: "Nayarit" },
  { clave: "NL", name: "Nuevo Leon" },
  { clave: "OAX", name: "Oaxaca" },
  { clave: "PUE", name: "Puebla" },
  { clave: "QR", name: "Quintana Roo" },
  { clave: "QRO", name: "Queretaro" },
  { clave: "SIN", name: "Sinaloa" },
  { clave: "SLP", name: "San Luis Potosi" },
  { clave: "SON", name: "Sonora" },
  { clave: "TAB", name: "Tabasco" },
  { clave: "TLX", name: "Tlaxcala" },
  { clave: "TMS", name: "Tamaulipas" },
  { clave: "VER", name: "Veracruz" },
  { clave: "YUC", name: "Yucatan" },
  { clave: "ZAC", name: "Zacatecas" },
];
