import * as React from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

import { enqueueSnackbar } from "notistack";
import "react-credit-cards/lib/styles-compiled.css";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";
import CardList from "../Shared/Components/CardList.component";

import { useTranslation } from "react-i18next";

export default function CardTransfer(props) {
  const { origin, cardList } = props;
  const [amount, setAmount] = React.useState(0);
  const [destinationCards, setDestinationCards] = React.useState([]);
  const [destination, setDestination] = React.useState({});
  const [valid, setValid] = React.useState(false);

  const { t } = useTranslation("common");

  const { token } = useAuth();

  useEffect(() => {
    validate();
  }, [amount, destination]);

  useEffect(() => {
    let cards = cardList.filter(function (card) {
      return card.number !== origin.number;
    });
    setDestinationCards(cards);
  }, [cardList]);

  const validate = () => {
    if (Object.keys(destination).length === 0) {
      return setValid(false);
    }
    if (amount === 0 || amount > parseFloat(origin.balance)) {
      return setValid(false);
    }
    setValid(true);
  };

  const onSelected = (card) => {
    setDestination(card);
  };

  const submit = async () => {
    if (valid) {
      await newCardTransfer();
    }
  };

  const newCardTransfer = async () => {
    let cardTransfer = {
      number: origin.number,
      amount: amount,
      destination: destination,
    };
    let response = await endpointRequest(
      "POST",
      "/card/transfer",
      cardTransfer,
      token
    );
    if (response.error === false) {
      enqueueSnackbar(t("dashboard.cards.transfers.success"), {
        variant: "success",
      });
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center !important",
        alignItems: "center",
        paddingBottom: "45px",
        maxWidth: "1250px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "auto",
          width: "100%",
          margin: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        }}
      >
        {t("dashboard.cards.transfers.title")}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={submit}
        sx={{ mt: 3, display: "flex", justifyContent: "center" }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Typography
            variant="h4"
            sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}
          >
            {t("dashboard.cards.transfers.origin")}
          </Typography>

          <CardList cards={[origin]} />

          <Typography
            variant="h6"
            sx={{ width: "100%", marginTop: 5, marginBottom: 5 }}
          >
            {t("dashboard.cards.transfers.origin-balance")}: ${origin.balance}
          </Typography>

          <Typography
            variant="h4"
            sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}
          >
            {t("dashboard.cards.transfers.destination")}
          </Typography>

          <CardList cards={destinationCards} onSelected={onSelected} />

          {!destination.number && (
            <Typography
              variant="h6"
              sx={{
                width: "100%",
                marginTop: 5,
                marginBottom: 5,
                textAlign: "center",
              }}
            >
              {t("dashboard.cards.transfers.select-card")}
            </Typography>
          )}

          {destinationCards.length === 0 && (
            <Typography sx={{ width: "100%", marginBottom: 5 }}>
              {t("dashboard.cards.transfers.one-card")}
            </Typography>
          )}

          {destination.number && (
            <React.Fragment>
              <Typography variant="h6" sx={{ width: "100%", marginTop: 5 }}>
                {t("dashboard.cards.transfers.destination-number")}: {destination.number}
              </Typography>
              <Typography variant="h6" sx={{ width: "100%", marginBottom: 5 }}>
                {t("dashboard.cards.transfers.destination-balance")}: ${destination.balance}
              </Typography>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="funds"
                  label={t("dashboard.cards.transfers.amount")}
                  name="funds"
                  onChange={(e) => setAmount(e.target.value)}
                  InputProps={{
                    maxLength: 5,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {amount > 0 ? (
                <Grid item xs={12}>
                  <Typography sx={{ width: "100%", marginBottom: 5 }}>
                    {t("dashboard.cards.transfers.warning")}: $ {amount * 0.99}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <Button
                  className="gradient-button"
                  sx={{ width: "100%" }}
                  disabled={!valid}
                  onClick={() => submit()}
                >
                  {t("dashboard.cards.transfers.submit")}
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Box>
    </Card>
  );
}
