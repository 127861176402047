import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { endpointRequest } from "../../Shared/http/httpService";

import Cards from "react-credit-cards";
import "react-credit-cards/lib/styles-compiled.css";

export default function Register(props) {
  const [step, setStep] = React.useState(0);
  const [number, setNumber] = React.useState("");
  const [activation, setActivation] = React.useState("");
  const [focus, setFocus] = React.useState("");
  const { t } = useTranslation("common");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    Array.from(formData.entries()).forEach(([key, value]) => {
      data[key] = value;
    });
    if (data.password === data["password-verify"]) {
      data.card = number;
      data.activation = activation;
      let response = await endpointRequest("POST", "/register", data);
      if (response && response.data && response.data.result) {
        enqueueSnackbar("Nueva cuenta creada exitosamente", {
          variant: "success",
        });
        handleStep(2);
      } else {
        enqueueSnackbar("Error al registrar la cuenta", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Las contraseñas no coinciden", {
        variant: "error",
      });
    }
  };

  const handleStep = (value) => {
    setStep(value);
  };

  const handleLogin = () => {
    props.onClose();
    window.__openLogin();
  };

  const close = () => {
    setStep(0);
    setNumber("");
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={() => close()}>
      <React.Fragment>
        <DialogTitle>
          {step === 0 ? t("register.card") : t("register.title")}
        </DialogTitle>
        <DialogContent>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button variant="contained" onClick={handleLogin}>
                {t("register.activate-card-on-existing-account")}
              </Button>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  {step === 0 ? (
                    <React.Fragment>
                      <Cards
                        number={number}
                        name={""}
                        expiry={""}
                        focused={focus}
                        cvc={""}
                      />
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="card"
                          label={t("register.card-number")}
                          name="card"
                          inputProps={{ maxLength: 16 }}
                          onChange={(e) => setNumber(e.target.value)}
                          onFocus={(e) => setFocus(e.target.name)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="activation"
                          label={t("register.card-activation-code")}
                          name="activation"
                          inputProps={{ maxLength: 8 }}
                          onChange={(e) => setActivation(e.target.value)}
                        />
                      </Grid>
                      <Button
                        fullWidth
                        variant="contained"
                        className="gradient-button"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={number.length < 16 || activation.length < 4}
                        onClick={() => handleStep(1)}
                      >
                        {t("register.card-register")}
                      </Button>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {step === 1 ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="name"
                          required
                          fullWidth
                          id="firstName"
                          label={t("register.first-name")}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label={t("register.last-name")}
                          name="lastName"
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label={t("register.email")}
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="username"
                          label={t("register.user")}
                          type="username"
                          id="username"
                          autoComplete="username"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label={t("register.password")}
                          type="password"
                          id="password"
                          autoComplete="new-password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          label={t("register.password-confirmation")}
                          type="password"
                          name="password-verify"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="phone"
                          label={t("register.phone")}
                          name="phone"
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={() => handleStep(0)}
                        >
                          {t("register.back-button")}
                        </Button>
                      </Grid>
                      <Grid item sm={6}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          className="gradient-button"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          {t("register.submit-button")}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {step === 2 ? <span></span> : ""}
                </Grid>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  );
}
