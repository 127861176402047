import * as React from "react";
import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import { styled, useTheme } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import CardHeader from "@mui/material/CardHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditIcon from "@mui/icons-material/Edit";

import { LanguageSelector } from "../../Shared/Components/LanguageSelector";
import { useAuth } from "../../Shared/Providers/Auth.provider";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const drawerWidth = "15vw";

const openedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const AppDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Nav(props) {
  const { logout } = useAuth();
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const [openUser, setOpenUser] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUser(false);
  };

  const handleOpenUser = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenUser(true);
  };

  const toggleDrawer = () => {
    setOpen(!open);
    props.onChange(open);
  };

  return (
    <React.Fragment>
      {!isMobile ? (
        <>
          <AppBar
            position="static"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
            }}
            style={{
              background: "transparent",
              boxShadow: "none",
              backgroundImage: "none",
            }}
          >
            <Toolbar>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: "inline-flex" }}>
                <TextField
                  id="search"
                  type="search"
                  placeholder={t("dashboard.home.search")}
                  size="small"
                  // value={searchTerm}
                  // onChange={handleChange}
                  sx={{
                    borderRadius: "15%",
                    mr: 4,
                    mt: "auto",
                    mb: "auto",
                    border: "none",
                    background:
                      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                    borderRadius: "6px",
                    "& fieldset": { border: "none" },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <LanguageSelector />
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                      borderRadius: "6px",
                      height: 40,
                      width: 40,
                      marginTop: "auto !important",
                      marginBottom: "auto !important",
                    }}
                  >
                    <NotificationsIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    variant="outlined"
                    component={Link}
                    to="/profile"
                    sx={{
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                      borderRadius: "6px",
                      height: 40,
                      width: 40,
                      marginTop: "auto !important",
                      marginBottom: "auto !important",
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    variant="outlined"
                    onClick={logout}
                    sx={{
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                      borderRadius: "6px",
                      height: 40,
                      width: 40,
                      marginTop: "auto !important",
                      marginBottom: "auto !important",
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={props.name}
                        src={props.image}
                        sx={{ bgcolor: "white" }}
                      />
                    }
                    title={props.name}
                  />
                </Stack>
              </Box>
            </Toolbar>
          </AppBar>

          <AppDrawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: open ? "15vw !important" : "4vw !important",
                boxSizing: "border-box",
              },
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#25164f",
                zIndex: "999 !important",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Box
              sx={{ width: "100%", textAlign: "center" }}
              role="presentation"
            >
              <IconButton
                onClick={toggleDrawer}
                sx={{ display: "block", position: "absolute", right: "2%" }}
              >
                {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
              <Link to="/">
                <Box
                  component="img"
                  src={open ? "/assets/logo.png" : "/assets/logo-icon.png"}
                  style={{
                    marginTop: "40px",
                    marginBottom: "20px",
                    width: "80%",
                  }}
                />
              </Link>
              <Divider
                sx={{
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "20px",
                }}
              />
              <List sx={{ textAlign: "center", alignItems: "center" }}>
                <ListItem
                  key={"Dashboard"}
                  component={Link}
                  to="/"
                  sx={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("dashboard.nav.home")}
                    sx={{ display: open ? "block" : "none" }}
                  />
                </ListItem>
                <ListItem
                  key={"Add Funds"}
                  component={Link}
                  to="funds"
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon>
                    <MonetizationOnIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("dashboard.nav.funds")}
                    sx={{ display: open ? "block" : "none" }}
                  />
                </ListItem>
              </List>
            </Box>
          </AppDrawer>
        </>
      ) : (
        <AppBar
          position="relative"
          sx={{ zIndex: 1, backgroundImage: "none !important" }}
        >
          <Toolbar>
            <>
              <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <LanguageSelector />
              <Avatar
                onClick={handleOpenUser}
                alt={props.name}
                src={props.image}
                sx={{ bgcolor: "white", marginLeft: 5 }}
              />
            </>
          </Toolbar>
          <Drawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onClick={() => setOpenDrawer(false)}
            PaperProps={{
              sx: {
                backgroundImage: "none !important",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "80vw" },
                maxWidth: "350px",
                textAlign: "center",
              }}
              role="presentation"
            >
              <Link to="/">
                <Box
                  component="img"
                  src="/assets/logo.png"
                  style={{
                    marginTop: "30px",
                    marginBottom: "20px",
                    width: "80%",
                  }}
                />
              </Link>
              <List>
                <ListItem
                  key={"Dashboard"}
                  component={Link}
                  to="/"
                  sx={{
                    paddingLeft: "44px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("dashboard.nav.home")} />
                </ListItem>
                <ListItem
                  key={"Add Funds"}
                  component={Link}
                  to="funds"
                  style={{
                    paddingLeft: "44px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <ListItemIcon>
                    <MonetizationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("dashboard.nav.funds")} />
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </AppBar>
      )}
      <Menu
        anchorEl={anchorEl}
        open={openUser}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuList>
          <MenuItem button component={Link} to="profile">
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("dashboard.nav.profile-edit")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={logout} button>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("dashboard.nav.logout")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </React.Fragment>
  );
}
