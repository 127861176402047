import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import AddIcon from "@mui/icons-material/Add";
import BalanceIcon from "@mui/icons-material/Balance";
import SavingsIcon from "@mui/icons-material/Savings";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import NumbersIcon from "@mui/icons-material/Numbers";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCardIcon from "@mui/icons-material/AddCard";

import { Link } from "react-router-dom";

import UsdtIcon from "../../Assets/usdt.png";
import CepIcon from "../../Assets/cep-icon.png";
import FundsIcon from "../../Assets/funds.png";
import MovementsIcon from "../../Assets/movements.png";
import SendIcon from "../../Assets/send.png";

export default function CardBox(props) {
  const statusIcon = (status) => {
    switch (status) {
      case "add":
        return <AddIcon className="card-icon" />;
      case "bank":
        return <AddIcon className="card-icon" />;
      case "balance":
        return <BalanceIcon className="card-icon" />;
      case "savings":
        return <SavingsIcon className="card-icon" />;
      case "transfer":
        return <CurrencyExchangeIcon className="card-icon" />;
      case "card":
        return <CreditCardIcon className="card-icon" />;
      case "send":
        return (
          <img
            height="50%"
            width="50%"
            src={SendIcon}
            className="card-icon"
            style={{
              margin: "auto",
              display: "flex",
            }}
          />
        );
      case "number":
        return <NumbersIcon className="card-icon" />;
      case "history":
        return (
          <img
            height="50%"
            width="50%"
            src={MovementsIcon}
            className="card-icon"
            style={{
              margin: "auto",
              display: "flex",
            }}
          />
        );
      case "list":
        return <FormatListBulletedIcon className="card-icon" />;
      case "newCard":
        return <AddCardIcon className="card-icon" />;
      case "crypto":
        return (
          <img
            height="50%"
            width="50%"
            src={UsdtIcon}
            className="card-icon"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
              margin: "auto",
            }}
          />
        );
      case "cep":
        return (
          <img
            height="50%"
            width="50%"
            src={CepIcon}
            className="card-icon"
            style={{
              margin: "auto",
              justifyContent: "center",
              display: "flex",
            }}
          />
        );
      case "funds":
        return (
          <img
            height="50%"
            width="50%"
            src={FundsIcon}
            className="card-icon"
            style={{
              margin: "auto",
              justifyContent: "center",
              display: "flex",
            }}
          />
        );
    }
  };

  const onClicked = () => {
    props.onClicked(props.name);
  };

  return (
    <Grid
      item
      xs={3}
      md={props.size ? props.size : 4}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            className="card-box"
            onClick={onClicked}
            sx={{
              width: "100%",
              maxWidth: { xs: "80px", sm: "480px" },
              height: { xs: "80px", sm: "100px", sm: "182px" },
              cursor: props.link !== "" ? "pointer" : "default",
              backgroundColor: props.selected ? "#554c6d !important" : "",
            }}
          >
            <Link
              to={props.link ? props.link : ""}
              style={{
                cursor: "inherit",
              }}
            >
              <CardContent>
                {props.loading === true ? (
                  <Skeleton />
                ) : (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      {statusIcon(props.icon)}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: {
                          xs: "none",
                          md: "block",
                        },
                        marginTop: "auto",
                        marginBottom: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h4" sx={{ fontWeight: "700" }}>
                        {props.header}
                      </Typography>
                      <Typography variant="h6">{props.text}</Typography>
                      {props.children}
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "13px",
              display: {
                xs: "block",
                md: "none",
              },
              fontWeight: "700",
            }}
          >
            {props.text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
