import * as React from "react";

import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useTranslation } from "react-i18next";

let countries = {
  title: "Países Aceptación",
  text: "Las tarjetas de Crypto en Pesos se encuentran disponibles globalmente, excepto en los siguientes países:\n\nPara su uso en Ecommerce:\nBolivia\nBosnia y Herzegovina\nSri Lanka\nEtiopia\nIran\nIraq\nKorea del Norte\nVanuatu\n\nParaguay\nSudan\nSyria\nTrinidad y Tobago\nTunisia\nVenezuela\n\nEn uso general Postnets:\nTodos los mencionados arriba\nPerú\nColombia\n\nNo puede retirarse dinero en ATM:\nTodos los mencionados arriba\nBrazil\nEcuador",
};
let whatsapp = {
  title: "Funciones de Whatsapp",
  text: "",
};
let costs = {
  title: "Costos Operativos",
  text: "",
};

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState({});
  const { t } = useTranslation("common");

  const handleOpen = (option) => {
    setOpen(true);
    setContent(option);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container sx={{ width: "100%", height: "auto", marginTop: 10 }}>
      <Button variant="contained" onClick={() => handleOpen(countries)}>
        {t("dashboard.footer.countries")}
      </Button>
      <Button
        variant="contained"
        onClick={() => handleOpen(whatsapp)}
        sx={{ margin: "5px" }}
      >
        {t("dashboard.footer.whatsapp")}
      </Button>
      <Button variant="contained" onClick={() => handleOpen(costs)}>
        {t("dashboard.footer.costs")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ whiteSpace: "pre-line" }}
          >
            {content.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("dashboard.footer.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
