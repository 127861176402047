import * as React from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { Routes, Route } from "react-router-dom";

import Landing from "./Landing/Landing.component";

import Dashboard from "./Dashboard/Dashboard.component";
import Home from "./Dashboard/Home/Home.component";
import NewTransferPage from "./Dashboard/Transfers/NewTransferPage.component";
import Profile from "./Dashboard/Profile/Profile.component";
import PageNotFound from "./Shared/Components/PageNotFound.component";
import Balance from "./Landing/Balance/Balance.component";

import { ProtectedRoute, AdminRoute } from "./Shared/Providers/Protected.route";
import { useAuth } from "./Shared/Providers/Auth.provider";

import { GlobalLoading } from "react-global-loading";
import CircularProgress from "@mui/material/CircularProgress";

import Theme from "./Shared/theme";
import NewCard from "./Dashboard/Cards/NewCard.component";

function App() {
  const { token } = useAuth();
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Routes>
      <Route path="balance" element={<Balance />} />
        <Route
          path="/"
          element={
            token ? (
              <Dashboard />
            ) : (
              <React.Fragment>
                <Landing />
              </React.Fragment>
            )
          }
        >
          <Route path="activation" element={<Dashboard />} />
          <Route
            path="transfers/new"
            element={
              <ProtectedRoute>
                <NewTransferPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="cards/new"
            element={
              <ProtectedRoute>
                <NewCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <GlobalLoading>
        <CircularProgress />
      </GlobalLoading>
    </ThemeProvider>
  );
}

export default App;
