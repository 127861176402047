import * as React from "react";
import { useEffect } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { enqueueSnackbar } from "notistack";

import { endpointRequest } from "../../../Shared/http/httpService";
import { useAuth } from "../../../Shared/Providers/Auth.provider";

export default function AccountNumberButton() {
  const [number, setNumber] = React.useState(0);

  const { token } = useAuth();

  useEffect(() => {
    getAccountNumber();
  }, []);

  const getAccountNumber = async () => {
    let response = await endpointRequest("GET", "/account/number", {}, token);
    if (response && response.data) setNumber(response.data.number);
  };

  return (
    <Button
      variant="outlined"
      onClick={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText(number);
        enqueueSnackbar("Número de cuenta copiado", {
          variant: "info",
        });
      }}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      sx={{
        marginBottom: "20px",
        color: "white",
        background: "rgba(255,255,255,0.2)",
        border: "1px solid transparent",
      }}
    >
      <ContentCopyIcon />
      <Typography variant="h7">{number}</Typography>
    </Button>
  );
}
