import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { CardContent, Typography } from "@mui/material";

import Card from "@mui/material/Card";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import { useTranslation } from "react-i18next";

export default function Benefits() {
  const { t } = useTranslation("common");
  return (
    <Container
      component="main"
      id="beneficios"
      sx={{
        minHeight: "60vh",
        textAlign: "center",
        paddingTop: "70px",
        paddingBottom: "70px",
      }}
    >
      <Container component="main">
        <Typography sx={{ fontSize: 45 }} color="text.primary" gutterBottom>
          {t("landing.benefits.title")}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} md={4}>
            <Card className="benefit">
              <CardContent>
                <Box className="benefit-image">
                  <img src="/assets/security.png" alt="image" />
                </Box>
                <Typography
                  sx={{ fontSize: 15, fontFamily: "atmospheric" }}
                  color="text.primary"
                >
                  {t("landing.benefits.security")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className="benefit">
              <CardContent>
                <Box className="benefit-image">
                  <img src="/assets/card.png" alt="image" />
                </Box>
                <Typography
                  sx={{ fontSize: 15, fontFamily: "atmospheric" }}
                  color="text.primary"
                >
                  {t("landing.benefits.card")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className="benefit">
              <CardContent>
                <Box className="benefit-image">
                  <img src="/assets/private.png" alt="image" />
                </Box>
                <Typography
                  sx={{ fontSize: 15, fontFamily: "atmospheric" }}
                  color="text.primary"
                >
                  {t("landing.benefits.speed")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
