import * as React from "react";
import Card from "@mui/material/Card";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PendingTransfers from "./Components/PendingTransfers.component";
import NewTransfer from "./NewTransfer.component";

import { useTranslation } from "react-i18next";

export default function TransferCard() {
  const [value, setValue] = React.useState("0");
  const { t } = useTranslation("common");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center !important",
        alignItems: "center",
        paddingBottom: "45px",
      }}
    >
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t("dashboard.transfers.new")} value="0" />
        <Tab label={t("dashboard.transfers.pending-title")} value="1" />
      </Tabs>

      {value === "0" ? <NewTransfer /> : ""}
      {value === "1" ? <PendingTransfers /> : ""}
    </Card>
  );
}
