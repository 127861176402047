import * as React from "react";
import Box from "@mui/material/Box";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import { Link, useNavigate } from "react-router-dom";

import CardIcon from "../Assets/card-icon.png";

import CardMovements from "./CardMovements.component";
import CardFunds from "./CardFunds.component";
import CardTransfer from "./CardTransfer.component";

import { useTranslation } from "react-i18next";

export default function CardList(props) {
  const [option, setOption] = React.useState("");
  const [card, setCard] = React.useState({
    name: "",
    number: "",
    balance: 0,
  });
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const onSelected = (card, option) => {
    setCard(card);
    setOption(option);
  };

  return (
    <Box
      sx={{
        mt: 2,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          mb: 10,
          minHeight: "250px",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "45px",
          tableLayout: "fixed",
        }}
      >
        {props.cards && props.cards.length && props.cards.length > 0 ? (
          <Table
            sx={{
              tableLayout: "fixed",
            }}
          >
            <TableHead sx={{ width: "100%" }}>
              <TableRow>
                <TableCell align="center" colSpan={7} sx={{ width: "100%" }}>
                  <Typography variant="h5">
                    {t("dashboard.cards.title")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ width: "100%" }}>
              <TableRow
                sx={{ backgroundColor: "#6C1C59 !important", width: "100%" }}
              >
                <TableCell align="center" colSpan={1}>
                  <img
                    width="35px"
                    src={CardIcon}
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                </TableCell>
                <TableCell align="center" colSpan={5}>
                  <Link to={"/cards/new"}>
                    <AddIcon style={{ verticalAlign: "middle" }} />
                    <Typography variant="h5">
                      {t("dashboard.cards.new.title")}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell align="center" colSpan={1}></TableCell>
              </TableRow>
              {props.cards.map((card) => (
                <TableRow
                  key={card.number}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" colSpan={1}>
                    <img width="35px" src={CardIcon} />
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "60%", sm: "100%" },
                          height: "21px",
                          verticalAlign: "middle",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {card.number}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onSelected(card, "movements")}
                        sx={{
                          width: "70%",
                          maxWidth: "250px",
                          minWidth: "60px",
                          justifyItems: "center",
                        }}
                        startIcon={
                          <Avatar
                            src={"assets/movements.png"}
                            sx={{
                              width: "auto",
                              borderRadius: "0 !important",
                              maxWidth: "30px !important",
                              maxHeight: "30px !important",
                            }}
                          />
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "100%",
                            display: { xs: "none", md: "block" },
                          }}
                        >
                          {t("dashboard.cards.movements")}
                        </Typography>
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "100%" }}>
                        ${card.balance}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: "70%",
                          maxWidth: "250px",
                          minWidth: "60px",
                          justifyItems: "center",
                        }}
                        onClick={() => onSelected(card, "funds")}
                        startIcon={
                          <Avatar
                            src={"assets/funds.png"}
                            sx={{
                              marginLeft: {
                                xs: "10px",
                                sm: "0px",
                                maxWidth: "30px !important",
                                maxHeight: "30px !important",
                              },
                            }}
                          />
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "100%",
                            display: { xs: "none", md: "block" },
                          }}
                        >
                          {t("dashboard.cards.add-funds")}
                        </Typography>
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "100%" }}>
                        <br></br>
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: "70%",
                          maxWidth: "260px",
                          minWidth: "60px",
                          textAlign: "center",
                        }}
                        disabled={props.cards.length < 2}
                        onClick={() => onSelected(card, "transfer")}
                        startIcon={
                          <Avatar
                            sx={{
                              width: "auto",
                              borderRadius: "0 !important",
                              maxWidth: "30px !important",
                              maxHeight: "30px !important",
                            }}
                            src={"assets/send.png"}
                          />
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "100%",
                            display: { xs: "none", md: "block" },
                          }}
                        >
                          {t("dashboard.cards.transfer")}
                        </Typography>
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div
            style={{
              margin: "auto",
            }}
          >
            <p>{t("dashboard.cards.empty")}</p>
          </div>
        )}
      </TableContainer>

      {option === "movements" ? (
        <CardMovements card={card} />
      ) : (
        ""
      )}
      {option === "funds" ? (
        <CardFunds card={card} cardList={props.cards} />
      ) : (
        ""
      )}
      {option === "transfer" ? (
        <CardTransfer origin={card} cardList={props.cards} />
      ) : (
        ""
      )}
    </Box>
  );
}
