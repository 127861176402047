import * as React from "react";
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

import { useOutletContext } from "react-router-dom";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

import AccountInfo from "../Shared/Components/AccountInfo.component";
import Movements from "../Movements/Movements.component";
import CardList from "../Cards/CardList.component";
import CardComponent from "../Shared/Components/Card.component";
import NewFunds from "../Funds/NewFunds.component";
import FundsTable from "../Funds/FundsTable.component";
import TransferCard from "../Transfers/TransferCard.component";
import Footer from "../Footer/Footer.component";

import { useTranslation } from "react-i18next";

let text = [
  "Este es el saldo de tu cuenta principal. Puedes hacer click aquí para cargar saldo.",
  "Aquí podrás utilizar tu saldo para realizar transferencias",
  "Este es tu número de cuenta. Haz click en este recuadro para copiarlo.",
  "Estas son las tarjetas asociadas a tu cuenta. Puedes tener varias.",
  "En este lugar se pueden ver los movimientos de la tarjeta seleccionada.",
];

let ids = ["balance", "transfers", "account", "cards", "movements"];

export default function Account() {
  const { token } = useAuth();
  const account = useOutletContext();

  const [balance, setBalance] = React.useState(0);
  const [cards, setCards] = React.useState([]);
  const [tutorial, setTutorial] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [card, setCard] = React.useState({ balance: 0 });
  const [option, setOption] = React.useState("cards");
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation("common");

  const handleClose = async () => {
    setAnchorEl(id);
    setTutorial(tutorial + 1);
    if (tutorial > 3) {
      await endpointRequest("POST", "/account/tutorial", {}, token);
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    getBalance();
    getCards();
  }, []);

  const getBalance = async () => {
    setLoading(true);
    let response = await endpointRequest("POST", "/account/balance", {}, token);
    if (response && response.data) setBalance(response.data);
    setLoading(false);
  };

  const getCards = async () => {
    let response = await endpointRequest("GET", "/card/list", {}, token);
    if (response && response.data) {
      setCards(response.data);
      setCard(response.data[0]);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? ids(tutorial) : undefined;

  return (
    <Container
      component="main"
      sx={{
        maxWidth: "1200px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
      maxWidth={false}
    >
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <AccountInfo aria-describedby="account" balance={balance} loading={loading} />
        <CardComponent text={t("dashboard.home.funds")} icon="cep" size="3" loading={loading} onClicked={() => setOption("funds")} selected={option === "funds"} />
        <CardComponent text={t("dashboard.home.transfer")} icon="send" size="3" loading={loading} onClicked={() => setOption("transfers")} selected={option === "transfers"} />
        <CardComponent text={t("dashboard.home.movements")} icon="history" size="3" loading={loading} onClicked={() => setOption("movements")} selected={option === "movements"} />
        <CardComponent text={t("dashboard.home.cards")} icon="funds" size="3" loading={loading} onClicked={() => setOption("cards")} selected={option === "cards"} />
        <Grid item xs={12} sx={{ paddingTop: "20px !important" }}>
          {option === "funds" ? (
            <React.Fragment>
              <NewFunds />
              <FundsTable />
            </React.Fragment>
          ) : (
            ""
          )}
          {option === "transfers" ? <TransferCard /> : ""}
          {option === "cards" ? <CardList cards={cards} /> : ""}
          {option === "movements" ? <Movements card={card} /> : ""}
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={account.tutorial && tutorial < 3}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{text[tutorial]}</Typography>
        <Button onClick={handleClose}>{tutorial < 4 ? "Siguiente" : "Terminar Tutorial"}</Button>
      </Popover>
      <Footer />
    </Container>
  );
}
