import * as React from "react";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { endpointRequest } from "../../../Shared/http/httpService";
import { useAuth } from "../../../Shared/Providers/Auth.provider";
import { useTranslation } from "react-i18next";

export default function PendingTransfers(props) {
  const { token } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [transfer, setTransfer] = React.useState(0);
  const [action, setAction] = React.useState(1);
  const [tempCode, setTempCode] = React.useState("");
  const { t } = useTranslation("common");
  const [pending, setPending] = React.useState([]);

  useEffect(() => {
    getPending();
  }, []);

  const getPending = async () => {
    let response = await endpointRequest("GET", "/transfer/pending", {}, token);
    if (response) setPending(response.data);
  };

  const confirm = async () => {
    let data = { transactionId: transfer, action: action, tempCode: tempCode };
    await endpointRequest("POST", "/transfer/confirm", data, token);
    close();
  };

  const close = () => {
    setTransfer(0);
    setTempCode("");
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Confirmar Transacción</DialogTitle>
        <DialogContent>
          <p>Ingresa el número de confirmación que fue enviado a tu email.</p>
          <TextField
            required
            fullWidth
            id="tempCode"
            label="Número de Confirmación"
            name="tempCode"
            className="profile-field"
            value={tempCode}
            onChange={(e) => setTempCode(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => close()}>
            Cancelar
          </Button>
          <Button variant="contained" className="gradient-button" onClick={() => confirm()}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 450, mb: 20 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("dashboard.transfers.pending.date")}</TableCell>
              <TableCell align="center">{t("dashboard.transfers.pending.id")}</TableCell>
              <TableCell align="center">{t("dashboard.transfers.pending.amount")}</TableCell>
              <TableCell align="center">{t("dashboard.transfers.pending.to")}</TableCell>
              <TableCell align="center">{t("dashboard.transfers.pending.concept")}</TableCell>
              <TableCell align="center">{t("dashboard.transfers.pending.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pending &&
              pending.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center">{row.fecha_alta}</TableCell>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.monto}</TableCell>
                  <TableCell align="center">{row.destino}</TableCell>
                  <TableCell align="center">{row.concepto}</TableCell>
                  <Box>
                    <Button
                      onClick={() => {
                        setAction(1);
                        setTransfer(row.id);
                        setOpen(true);
                      }}
                    >
                      <CheckIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        setAction(2);
                        setTransfer(row.id);
                        setOpen(true);
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
