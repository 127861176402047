import * as React from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";

import Nav from "../Shared/AppBar.component";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useSearchParams } from "react-router-dom";

import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

import { endpointRequest } from "../../Shared/http/httpService";

export default function Balance() {
  const [balance, setBalance] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("common");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.size > 0 && searchParams.get("number") !== undefined) {
      getBalance(searchParams.get("number"),searchParams.get("code"), );
    }
  }, [searchParams]);

  const getBalance = async (number, code) => {
    let data = {
      number: number,
      code: code,
    };
    let response = await endpointRequest("POST", "/login/balance", data);
    try {
      setBalance(response.data.balance);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(#4205A8, rgb(21, 7, 60))",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url("/assets/bg_texture.png")`,
          backgroundRepeat: "repeat-y",
          backgroundPositionX: "center",
          backgroundPositionY: "top",
          backgroundSize: "cover",
        }}
      >
        <Box sx={{ flexGrow: 1, position: "relative", zIndex: 10 }}>
          <Nav />
        </Box>
        <Container
          component="main"
          sx={{ minHeight: "95vh", paddingTop: "120px", paddingBottom: "60px", display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "440px",
              height: "240px",
              maxWidth: isMobile ? 200 : 1200,
              marginLeft: isMobile ? "auto" : "",
              marginRight: isMobile ? "auto" : "",
              background: "rgb(88, 35, 176)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "14px"
            }}
          >
            <Typography
            sx={{ fontSize: 25, textAlign: "center" }}
            color="text.primary"
            gutterBottom
          >Consulta de Saldo</Typography>
            Saldo de tarjeta: $ {balance}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
