import * as React from "react";
import Box from "@mui/material/Box";

import Nav from "./Shared/AppBar.component";
import Home from "./Home/Home.component";
import About from "./How/How.component";
import Benefits from "./Benefits/Benefits.component";
import Contact from "./Contact/Contact.component";

export default function Landing() {
  return (
    <Box
      sx={{
        background: "linear-gradient(#4205A8, rgb(21, 7, 60))",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url("/assets/bg_texture.png")`,
          backgroundRepeat: "repeat-y",
          backgroundPositionX: "center",
          backgroundPositionY: "top",
          backgroundSize: "cover",
        }}
      >
        <Box sx={{ flexGrow: 1, position: "relative", zIndex: 10 }}>
          <Nav />
        </Box>
        <Home />
        <Benefits />
        <About />
        <Contact />
      </Box>
    </Box>
  );
}
