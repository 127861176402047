import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function Contact() {
  const socialMediaList = [
    {
      name: "Facebook",
      img: "/assets/facebook.png",
      link: "https://facebook.com/cryptoenpesos",
    },
    {
      name: "Instagram",
      img: "/assets/instagram.png",
      link: "https://instagram.com/cryptoenpesos",
    },
    {
      name: "Telegram",
      img: "/assets/telegram.png",
      link: "https://t.me/cryptoenpesos",
    },
  ];

  return (
    <Container
      component="main"
      maxWidth="false"
      id="comunidad"
      sx={{
        paddingTop: "8px",
        paddingBottom: "8px",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        borderTop: "2px solid rgba(255, 255, 255, 0.35)",
      }}
    >
      <Grid
        container
        spacing={5}
        sx={{
          maxWidth: "1200px",
        }}
      >
        <Grid item xs={4} md={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ textAlign: "center", display: "flex", alignItems: "center" }}
          >
            © 2023 CryptoToPesos
          </Typography>
        </Grid>
        <Grid item xs={8} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {socialMediaList.map((socialMedia, index) => (
              <Link
                href={socialMedia.link}
                target="_blank"
                sx={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                key={index}
              >
                <Box className="social-media">
                  <Box
                    component="img"
                    src={socialMedia.img}
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </Box>
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
