import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import AccountNumberButton from "./AccountNumberButton.component";

import { useTranslation } from "react-i18next";

export default function AccountInfo(props) {
  const { t } = useTranslation("common");

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          width: "100%",
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
          "align-content": "center",
          "background-image": "rgba(255, 255, 255, 0.05)",
        }}
      >
        <CardContent sx={{ paddingBottom: "0 !important" }}>
          {props.loading === true ? (
            <Skeleton />
          ) : (
            <Grid
              container
              spacing={2}
              style={{
                margin: "auto",
              }}
            >
              <Typography sx={{ width: "100%", marginBottom: 0 }} variant="h6">
                {t("dashboard.account.balance")}
              </Typography>
              <Typography
                paragraph
                variant="h4"
                sx={{
                  fontWeight: "800",
                  width: "100%",
                  marginBottom: 0,
                }}
              >
                {"$" + props.balance}
                <Tooltip
                  title={t("dashboard.account.balance-tooltip")}
                  enterTouchDelay={0}
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  <InfoIcon></InfoIcon>
                </Tooltip>
              </Typography>
              <Typography sx={{ width: "100%" }}>Alias: TECHNOLOGY AND INTEROPERABILITY SA DE CV</Typography>
              <Typography sx={{ width: "100%" }}>
                {t("dashboard.account.institution")}: SPEI - TRANSFER
              </Typography>
              <AccountNumberButton />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
