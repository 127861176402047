import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CardMedia from "@mui/material/CardMedia";

import Autocomplete from "@mui/material/Autocomplete";

import { enqueueSnackbar } from "notistack";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";

import { endpointRequest } from "../../Shared/http/httpService";

import { useTranslation } from "react-i18next";

import { cities } from "../../Shared/cities";
import { states } from "../../Shared/states";

export default function CardRequest(props) {
  const [card, setCard] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [amount, setAmount] = React.useState(1);
  const [cardForm, setCardForm] = React.useState({});
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const { t } = useTranslation("common");
  const price = 700;

  const myRef = React.useRef(null);

  const handlePay = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    Array.from(formData.entries()).forEach(([key, value]) => {
      data[key] = value;
    });
    data.state = selectedState;
    data.city = selectedCity;
    setCardForm(data);
    cardForm.paid = cardForm.amount * price;
    let response = await endpointRequest("POST", "/register/card", cardForm);
    if (response && response.data && response.data.result) {
      enqueueSnackbar("Tarjeta/s solicitada/s exitosamente", {
        variant: "success",
      });
      setSubmitted(true);
    } else {
      enqueueSnackbar("Error al registrar la cuenta", {
        variant: "error",
      });
    }
  };

  const stateSelected = (event, value) => {
    setSelectedState(value.name);
  };

  const citySelected = (event, value) => {
    setSelectedCity(value);
    console.log(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    Array.from(formData.entries()).forEach(([key, value]) => {
      data[key] = value;
    });
    if (data.password === data["password-verify"]) {
      let response = await endpointRequest("POST", "/register", data);
      if (response && response.data && response.data.result) {
        enqueueSnackbar("Nueva cuenta creada exitosamente", {
          variant: "success",
        });
        setSubmitted(true);
      } else {
        enqueueSnackbar("Error al registrar la cuenta", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Las contraseñas no coinciden", {
        variant: "error",
      });
    }
  };

  const close = () => {
    setSubmitted(false);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={() => close()}>
      {!submitted ? (
        <React.Fragment>
          <DialogTitle>{t("card-request.title")}</DialogTitle>
          <DialogContent>
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component="form"
                  noValidate
                  onSubmit={card ? handleSubmit : handlePay}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={2}>
                    <React.Fragment>
                      {t("card-request.subtitle")}
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="given-name"
                          name="name"
                          required
                          fullWidth
                          id="recipientName"
                          label={t("card-request.names")}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label={t("card-request.email")}
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="address"
                          label={t("card-request.address")}
                          name="address"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="state-list"
                          name="state"
                          fullWidth
                          options={states}
                          onChange={stateSelected}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("card-request.state")}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="city-list"
                          name="city"
                          fullWidth
                          disabled={selectedState === null}
                          options={cities[selectedState]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("card-request.city")}
                            />
                          )}
                          onChange={citySelected}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="postalCode"
                          label={t("card-request.postal-code")}
                          name="postalCode"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {t("card-request.card-instructions")}
                        </Typography>
                        <Typography>
                          {t("card-request.card-cost")} ${price} MXN
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="Amount"
                          label={t("card-request.card-amount")}
                          type="number"
                          name="amount"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ inputProps: { min: 1, max: 1000 } }}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {t("card-request.card-total")}: ${amount * price}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="gradient-button"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t("card-request.pay")}
                  </Button>
                </Box>
              </Box>
            </Container>
          </DialogContent>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CardMedia
            sx={{
              backgroundColor: "#459145",
              textAlign: "center",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <CheckCircleIcon sx={{ width: "120px", height: "120px" }} />
          </CardMedia>
          <DialogContent>
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography color="text.primary" variant="h4" gutterBottom>
                  {t("card-request.success-message")}
                </Typography>
                <Typography color="text.primary" variant="h6" gutterBottom>
                  {t("card-request.success-message")}
                </Typography>
                <Button
                  onClick={close}
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#459145",
                    marginTop: 3,
                    ":hover": {
                      bgcolor: "#285e28",
                      color: "white",
                    },
                  }}
                >
                  {t("card-request.success-button")}
                </Button>
              </Box>
            </Container>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
}
