import * as React from "react";
import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";

import Login from "../Login/Login.component";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../Shared/Components/LanguageSelector";

import { createContext } from "react";
export const NavContext = createContext({});

export default function Nav() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation("common");

  const handleClick = (element) => {
    document.getElementById(element).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <React.Fragment>
      {!isMobile ? (
        <AppBar
          position="static"
          sx={{ background: "transparent", boxShadow: "none" }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-around" }}>
            <Link to="/">
              <Box
                component="img"
                src="/assets/logo-icon.png"
                style={{
                  width: "80px",
                }}
              />
            </Link>
            <Box>
              <Button
                color="inherit"
                sx={{ paddingLeft: 5, paddingRight: 5 }}
                onClick={() => handleClick("beneficios")}
              >
                {t("nav.benefits")}
              </Button>
              <Button
                color="inherit"
                sx={{ paddingLeft: 5, paddingRight: 5 }}
                onClick={() => handleClick("como-funciona")}
              >
                {t("nav.how")}
              </Button>
              <Button
                color="inherit"
                sx={{ paddingLeft: 5, paddingRight: 5 }}
                onClick={() => handleClick("comunidad")}
              >
                {t("nav.community")}
              </Button>
            </Box>
            <LanguageSelector bg="linear-gradient(45deg, #7c00ec, #d800d8)" />
            <Login />
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="relative"
          sx={{
            zIndex: 1,
            background: "transparent",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <>
              <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <LanguageSelector />
              <Box sx={{ marginLeft: 5 }} />
              <Login />
            </>
          </Toolbar>
          <Drawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onClick={() => setOpenDrawer(false)}
          >
            <Link to="/">
              <Box
                component="img"
                src="/assets/logo.png"
                style={{
                  marginTop: "30px",
                  marginBottom: "20px",
                  width: "300px",
                }}
              />
            </Link>
            <List>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>{t("nav.benefits")}</ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>{t("nav.how")}</ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>{t("nav.community")}</ListItemText>
              </ListItem>
            </List>
          </Drawer>
        </AppBar>
      )}
    </React.Fragment>
  );
}
