import * as React from "react";

import Card from "@mui/material/Card";
import { Typography } from "@mui/material";

import NewTransfer from "./NewTransfer.component";

export default function NewTransferPage() {
  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: { xs: "100%", md: "40%" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center !important",
        alignItems: "center",
        paddingBottom: "45px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "auto",
          width: "100%",
          margin: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        }}
      >
        Nueva Transferencia
      </Typography>
      <NewTransfer />
    </Card>
  );
}
