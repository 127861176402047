import * as React from "react";
import { useRef, useEffect } from "react";
import Box from "@mui/material/Box";

import Nav from "./Shared/AppBar.component";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from '@mui/material/Fab';

import { useAuth } from "../Shared/Providers/Auth.provider";
import { endpointRequest } from "../Shared/http/httpService";

import Tutorial from "./Tutorial/Tutorial.component";

import { Outlet } from "react-router-dom";

export default function Dashboard() {
  const [name, setName] = React.useState("");
  const [image, setImage] = React.useState("");
  const [account, setAccount] = React.useState({});
  const [tutorial, setTutorial] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { token } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    getAccount();
  }, []);

  const onClose = async () => {
    setTutorial(false);
  };

  const onChange = (isOpen) => {
    setOpen(isOpen);
  };

  const onStart = async () => {
    setTutorial(false);
    setAccount((prevState) => ({
      ...prevState,
      tutorial: true,
    }));
  };

  const getAccount = async () => {
    let response = await endpointRequest("GET", "/account", {}, token);
    if (response && response.data.account) {
      setName(
        response.data.account.name + " " + response.data.account.lastName
      );
      setImage(response.data.account.image);
      setAccount(response.data.account);
      if (response.data.account.new === true) {
        setTutorial(true);
      }
    }
  };

  const getMargins = (status, mobile, origin) => {
    if(mobile) {
      return 0;
    }
    if (status) {
      return origin === "left" ? "5vw" : "2vw";
    }
    else {
      return origin === "left" ? "15vw" : "5vw";
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#15073C",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Tutorial open={tutorial} close={onClose} start={onStart} />
      <Nav name={name} image={image} onChange={onChange}/>
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: getMargins(open, isMobile, "left"),
          marginRight: getMargins(open, isMobile, "right"), 
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Outlet context={account} />
        <Fab target="_blank" sx={{position: "fixed", left: "auto", right: 2, bottom: 20}} color="primary" aria-label="add" href="https://wa.me/5219982904034">
          <img
              height="100%"
              width="100%"
              src={"/assets/whatsapp.png"}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
              }}
            />
        </Fab>
      </Box>
    </div>
  );
}
