import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

import CoinbaseCommerceButton from "react-coinbase-commerce";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";

import { useAuth } from "../../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../../Shared/http/httpService";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Coinbase(props) {
  const { token } = useAuth();
  const [exchange, setExchange] = React.useState("");
  const [commission, setCommission] = React.useState(1.5);
  const [exchangeCommission, setExchangeCommission] = React.useState(1);
  const [amount, setAmount] = React.useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [valid, setValid] = React.useState(false);

  useEffect(() => {
    getExchangeRate();
    getCommission();
  }, []);

  useEffect(() => {
    validate();
  }, [amount]);

  const changeAmount = (e) => {
    let am =
      (e.target.value / exchange) *
      (1 + (commission.system + commission.coinbase) / 100);
    setAmount(Math.round(parseFloat(am) * 100) / 100);
  };

  const getExchangeRate = async () => {
    let response = await endpointRequest(
      "POST",
      "/crypto",
      {
        currency: "USDT",
        to: "MXN",
      },
      token
    );
    if (response.data) {
      setExchange(response.data);
    }
  };

  const getCommission = async () => {
    let response = await endpointRequest("GET", "/bank/commission", {}, token);
    if (response) setCommission(response.data.commission);
  };

  const myRef = React.useRef(null);

  const handleClick = () => {
    myRef.current.handleButtonClick();
  };

  const handleSubmit = async (MessageData) => {
    const data = { charge: MessageData.CHARGE_CODE };
    await endpointRequest("POST", "/funds/coinbase", data, token);
    props.onPaid({ charge: MessageData.CHARGE_CODE });
  };

  const handleClose = async () => {
    navigate("/funds", { replace: true });
  };

  const validate = () => {
    if (amount === 0 || amount > parseFloat(origin.balance)) {
      return setValid(false);
    }
    setValid(true);
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 3, width: { xs: "90%", md: "100%" } }}
    >
      {exchange ? (
        <Typography component="h1" variant="h6">
          {"1 USDT -> $" + Math.round(parseFloat(exchange) * 100) / 100 + "MXN"}
        </Typography>
      ) : (
        ""
      )}
      <Typography component="h1" variant="h6">
        {t("dashboard.funds.commision-crypto")}: {exchangeCommission}%
      </Typography>
      <Typography component="h1" variant="h6">
        {t("dashboard.funds.commision-cep")}: {commission}%
      </Typography>

      <FormControl fullWidth sx={{ m: 1 }} onChange={changeAmount}>
        <InputLabel htmlFor="outlined-adornment-amount">
          {t("dashboard.funds.amount")}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={
            <InputAdornment position="start">MXN $</InputAdornment>
          }
          label="Amount"
        />
      </FormControl>

      <Typography component="h1" variant="h6">
        {t("dashboard.funds.pay-info", { amount: amount })}
      </Typography>

      <CoinbaseCommerceButton
        checkoutId={"41ffc6e8-7188-4962-9024-a72e1c4af340"}
        onChargeSuccess={handleSubmit}
        onModalClosed={handleClose}
        ref={myRef}
        style={{ display: "none" }}
      />
      <Button
        fullWidth
        variant="contained"
        disabled={!valid}
        sx={{ mt: 3, mb: 2 }}
        onClick={handleClick}
      >
        {t("dashboard.funds.submit")}
      </Button>
    </Box>
  );
}
