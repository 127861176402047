import * as React from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { useTranslation } from "react-i18next";

export default function Movements(props) {
  const [movements, setMovements] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation("common");

  const { token } = useAuth();

  useEffect(() => {
    getMovements();
  }, [props.card]);

  const getMovements = async () => {
    setLoading(true);
    let response = await endpointRequest("GET", "/bank/movements", { card: props.card.number }, token);
    if (response.data) {
      setMovements(response.data);
      setLoading(false);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start !important",
        alignItems: "center",
        maxHeight: "440px",
        overflowY: "scroll",
      }}
      onClick={getMovements}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "auto",
          width: "100%",
          margin: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        }}
      >
        {t("dashboard.movements.title")}
      </Typography>
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
        }}
      >
        {loading === true ? (
          <>
            {Array(5)
              .fill(null)
              .map(() => (
                <Grid
                  container
                  sx={{
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    mt: 3,
                  }}
                >
                  <Grid
                    item
                    xs={2}
                    sx={{
                      marginBottom: "24px",
                    }}
                  >
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      paddingTop: "10px",
                    }}
                  >
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6, marginLeft: "14%" }} />
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6, marginLeft: "14%" }} />
                  </Grid>
                </Grid>
              ))}
          </>
        ) : (
          <List>
            {movements.map((movement) => (
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                    {movement.value > 0 ? <ArrowUpwardIcon sx={{ fill: "rgb(0,180,0)" }} /> : <ArrowDownwardIcon sx={{ fill: "rgb(180,0,0)" }} />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={movement.name}
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: "inline" }} component="span" variant="body2">
                        {movement.date}
                      </Typography>
                      <Typography color="text.primary" sx={{ float: "right" }}>
                        {movement.value}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Card>
  );
}
