import * as React from "react";
import Box from "@mui/material/Box";

import Coinbase from "./Coinbase.component";
import Ethereum from "./Ethereum.component";

export default function PaymentInfo(props) {
  const onPaid = async (info) => {
    props.onPaid(info);
  };

  return (
    <Box sx={{ width: "fit-content" }}>
      {props.method === "Coinbase" ? <Coinbase onPaid={onPaid} /> : ""}
      {props.method === "Ethereum" ? <Ethereum /> : ""}
    </Box>
  );
}
