import * as React from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@mui/material";

import { enqueueSnackbar } from "notistack";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import { useTranslation } from "react-i18next";

export default function CardFunds(props) {
  const { card } = props;
  const [balance, setBalance] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [valid, setValid] = React.useState(false);
  const { token } = useAuth();
  const { t } = useTranslation("common");

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    validate();
  }, [amount]);

  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    let value = e.target.value;
    if (value === "" || re.test(value)) {
      setAmount(value);
    }
  };

  const getBalance = async () => {
    let response = await endpointRequest("POST", "/account/balance", {}, token);
    if (response && response.data) setBalance(response.data);
  };

  const validate = () => {
    if (amount === 0 || amount > balance) {
       setValid(false);
    }
    return setValid(true);
  };

  const submit = async () => {
    if (valid) {
      await newCardFunds();
    }
  };

  const newCardFunds = async () => {
    let cardFunds = {
      number: card.number,
      amount: amount,
    };
    let response = await endpointRequest(
      "POST",
      "/card/funds",
      cardFunds,
      token
    );
    if (response.data && response.data.error === false) {
      enqueueSnackbar(t("dashboard.cards.funds.success"), {
        variant: "success",
      });
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center !important",
        alignItems: "center",
        paddingBottom: "45px",
        maxWidth: "1250px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "auto",
          width: "100%",
          margin: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        }}
      >
        {t("dashboard.cards.funds.title")}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={submit}
        sx={{ mt: 3, width: { xs: "100%", sm: "50%" } }}
      >
        <Grid container spacing={2}>
          <Typography variant="h6" sx={{ width: "100%", marginTop: 5 }}>
            {t("dashboard.cards.funds.account-balance")}: ${balance}
          </Typography>
          <Typography variant="h6" sx={{ width: "100%" }}>
            {t("dashboard.cards.funds.card-number")}: {card.number}
          </Typography>
          <Typography variant="h6" sx={{ width: "100%" }}>
            {t("dashboard.cards.funds.card-balance")}: ${card.balance}
          </Typography>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="funds"
              label={t("dashboard.cards.funds.amount")}
              name="funds"
              inputProps={{ maxLength: 5 }}
              onChange={onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={amount}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ marginTop: "3%" }}>
          {t("dashboard.cards.funds.warning")}
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
          className="gradient-button"
          disabled={!valid}
          onClick={() => submit()}
        >
          {t("dashboard.cards.funds.submit")}
        </Button>
      </Box>
    </Card>
  );
}
