import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import CoinbaseLogo from "../../Assets/binance.svg";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

let platforms = [
  {
    name: "Coinbase",
    image: CoinbaseLogo,
  },
];

export default function PaymentMethod(props) {
  const onPaid = async () => {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h5" className="container-title">
        Método de Pago
      </Typography>
      <Grid
        container
        spacing={5}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {platforms &&
          platforms.map((platform) => (
            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  width: "100%",
                  marginBottom: "15px",
                  display: "inline-block",
                  // border:
                  // platform.name === payment ? "2px solid white" : "none",
                }}
                onClick={() => props.onMethod(platform.name)}
                className="card-box"
              >
                <CardMedia
                  component="img"
                  width="300"
                  height="194"
                  image={platform.image}
                  alt={platform.name}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
