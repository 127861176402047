import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import PaymentMethod from "./Steps/PaymentMethod.component";
import PaymentInfo from "./Steps/PaymentInfo.component";
import PaymentComplete from "./Steps/PaymentComplete.component";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";

const steps = ["1. Método de pago", "2. Información de pago", "3. Completar el pago"];

export default function NewFunds() {
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState({});
  const [method, setMethod] = React.useState("Ethereum");
  const { t } = useTranslation("common");

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const onMethod = async (selected) => {
    setMethod(selected);
    handleComplete();
  };

  const onPaid = async () => {
    handleComplete();
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <PaymentMethod onMethod={onMethod} />;
      case 1:
        return <PaymentInfo method={method} onPaid={onPaid} />;
      case 2:
        return <PaymentComplete />;
    }
  };

  const changeMethod = (event) => {
    setMethod(event.target.value);
  };

  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center !important",
        alignItems: "center",
        paddingBottom: "45px",
        maxWidth: "1250px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "auto",
          width: "100%",
          margin: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        }}
      >
        {t("dashboard.funds.title")}
      </Typography>
      {allStepsCompleted() ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed!</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>{renderSwitch(activeStep)}</React.Fragment>
      )}
    </Card>
  );
}

{
  {
    /* <Box sx={{ width: "fit-content" }}>
<FormControl fullWidth>
  <Select
    label="Método de pago"
    value={method}
    onChange={changeMethod}
  >
    <MenuItem value={'Coinbase'}>Coinbase</MenuItem>
    <MenuItem value={'Ethereum'}>Ethereum</MenuItem>
  </Select>
</FormControl>
</Box> */
  }
}
