import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

import { endpointRequest } from "../../../Shared/http/httpService";
import { useAuth } from "../../../Shared/Providers/Auth.provider";
import { useTranslation } from "react-i18next";

import EthereumQRPlugin from "ethereum-qr-code";
import QRCode from "react-qr-code";

export default function Ethereum(props) {
  const { token } = useAuth();
  const { t } = useTranslation("common");
  const [exchange, setExchange] = React.useState("");
  const [commission, setCommission] = React.useState({
    system: 1.5,
    coinbase: 1,
  });
  const [amount, setAmount] = React.useState(0);
  const [address, setAddress] = React.useState("");
  const [valid, setValid] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [qrImage, setQrImage] = React.useState("");

  const qr = new EthereumQRPlugin();

  useEffect(() => {
    getExchangeRate();
  }, []);

  useEffect(() => {
    validate();
  }, [amount]);

  useEffect(() => {
    if (address !== "") {
      console.log(address);
      /*const qrCode = qr.toDataUrl({
        to: address,
        gas: 21000,
      });
      qrCode.then((code) => {
        console.log('Your QR id generated:', code);
        setQrImage(code.dataURL);
      })*/
    }
  }, [address]);

  const getExchangeRate = async () => {
    let response = await endpointRequest(
      "POST",
      "/crypto",
      {
        currency: "USDT",
        to: "MXN",
      },
      token
    );
    if (response.data) {
      setExchange(response.data);
    }
  };

  const validate = () => {
    if (amount === 0 || amount > parseFloat(origin.balance)) {
      return setValid(false);
    }
    setValid(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      address: address,
      amount: amount,
    };
    let response = await endpointRequest("POST", "/funds", data, token);
    if (response.data) {
      setAddress(response.data.address);
      setOpen(true);
    }
  };

  const changeAmount = (e) => {
    let am = (e.target.value / exchange) * (1 + (commission.system + commission.coinbase) / 100);
    setAmount(Math.round(parseFloat(am) * 100) / 100);
    console.log(amount);
  };

  const handleConfirm = async (event) => {
    setOpen(false);
    // props.onPaid();
  };

  const close = async () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {exchange ? (
            <Typography component="h1" variant="h6">
              {"1 USDT -> $" + Math.round(parseFloat(exchange) * 100) / 100 + "MXN"}
            </Typography>
          ) : (
            ""
          )}
          <Typography component="h1" variant="h6">
            {t("dashboard.funds.commision-eth")}: {commission.coinbase}%
          </Typography>
          <Typography component="h1" variant="h6">
            {t("dashboard.funds.commision-cep")}: {commission.system}%
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: { xs: "90%", md: "100%" } }}>
            <FormControl fullWidth sx={{ m: 1 }} onChange={changeAmount}>
              <InputLabel htmlFor="outlined-adornment-amount">{t("dashboard.funds.amount")}</InputLabel>
              <OutlinedInput id="outlined-adornment-amount" startAdornment={<InputAdornment position="start">MXN $</InputAdornment>} label="Amount" />
            </FormControl>
            <Typography component="h1" variant="h6">
              {t("dashboard.funds.pay-info", { amount: amount })}
            </Typography>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Pagar
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Datos de la wallet</DialogTitle>
        <DialogContent>
          <Typography>Listo! Se ha generado una solicitud de pago. Para realizar el pago debes transferir: ${amount} USDT a la siguiente wallet.</Typography>
          Dirección: {address}
          <Typography>Una vez realizado el pago se acreditarán los fondos en tu cuenta.</Typography>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <QRCode
            sx={{
              margin: "auto",
            }}
            value={address}
          />
        </Box>
        <DialogActions>
          <Button fullWidth variant="contained" disabled={!valid} onClick={handleConfirm}>
            {t("dashboard.funds.submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
