import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";

import { useTranslation } from "react-i18next";

export default function FundsTable() {
  const [balance, setBalance] = React.useState(0);
  const [funds, setFunds] = React.useState([]);
  const [balanceLoading, setBalanceLoading] = React.useState(false);
  const { t } = useTranslation("common");

  const { token } = useAuth();

  useEffect(() => {
    getBalance();
    getFunds();
  }, []);

  const getBalance = async () => {
    setBalanceLoading(true);
    let response = await endpointRequest("POST", "/account/balance", {}, token);
    if (response && response.data) {
      setBalance(parseFloat(response.data));
      setBalanceLoading(false);
    }
  };

  const getFunds = async () => {
    let response = await endpointRequest("GET", "/funds", {}, token);
    if (response) {
      setFunds(response.data);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#25164f",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center !important",
        alignItems: "center",
        marginTop: 5,
        maxWidth: "1250px!important",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "auto",
          width: "100%",
          margin: 0,
          paddingTop: "15px",
          paddingBottom: "15px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        }}
      >
        {t("dashboard.funds.table")}
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: "#1A0535", maxWidth: "1240px!important" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#1A0535" }}>
            <TableRow>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">Monto</TableCell>
              <TableCell align="center">Wallet</TableCell>
              <TableCell align="center">Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funds &&
              funds.map((row) => (
                <TableRow key={row.createdAt}>
                  <TableCell align="center">{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell align="center">{row.amount + row.currency}</TableCell>
                  <TableCell align="center">{row.wallet}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
