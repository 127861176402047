import * as React from "react";
import { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { useSearchParams } from "react-router-dom";

import Register from "../Register/Register.component";
import CardRequest from "../CardRequest/CardRequest.component";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { endpointRequest } from "../../Shared/http/httpService";

import { useTranslation } from "react-i18next";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { DialogContent, DialogTitle } from "@mui/material";

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [openCardRequest, setCardRequestOpen] = React.useState(false);
  const { t } = useTranslation("common");
  const [searchParams, setSearchParams] = useSearchParams();
  const [valid, setValid] = React.useState(false);
  const [activationModal, setActivationModal] = React.useState(false);

  const onClose = () => {
    setOpen(false);
    setCardRequestOpen(false);
  };

  useEffect(() => {
    if (searchParams.size > 0 && searchParams.get("number") !== undefined) {
      activate(searchParams.get("number"), searchParams.get("activation"));
    }
  }, [searchParams]);

  const activate = async (account, activation) => {
    const data = { number: account, activation: activation };
    let response = await endpointRequest("POST", "/register/activation", data);
    if (response.data && response.data.done) {
      setActivationModal(true);
      setValid(true);
    } else {
      setActivationModal(true);
      setValid(false);
    }
  };

  const handleClose = () => {
    setActivationModal(false);
  };

  return (
    <React.Fragment>
      <Dialog open={activationModal} onClose={handleClose}>
        <DialogTitle>Activacion de cuenta</DialogTitle>
        <DialogContent>
          {valid ? (
            <span>Tu cuenta fue activada con exito</span>
          ) : (
            <span>Hubo problemas al activar tu cuenta</span>
          )}
        </DialogContent>
      </Dialog>
      <Container
        component="main"
        sx={{
          height: "95vh",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: `url("/assets/bg_nodes.png")`,
            backgroundRepeat: "no-repeat",
            left: 0,
            right: 0,
            bottom: 0,
            backgroundPositionX: "center",
            backgroundPositionY: "105%",
            overflowY: "hidden",
          }}
        />
        <Grid
          container
          sx={{
            minWidth: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          alignItems="center"
          justify="center"
        >
          <Box
            component="img"
            src="/assets/logo.png"
            style={{ width: isMobile ? "100%" : "700px", marginBottom: 14 }}
            className="animate__animated animate__flipInX"
          />
          <Typography
            sx={{
              fontSize: 15,
              marginBottom: 1,
              fontFamily: "atmospheric",
            }}
            color="text.secondary"
            gutterBottom
            className="animate__animated animate__bounceIn animate__delay-1s"
          >
            {t("landing.subtitle")}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="large"
                className="animate__animated animate__fadeIn animate__delay-1.5s gradient-button"
                onClick={() => setCardRequestOpen(true)}
                sx={{
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                {t("landing.request-card-button")}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant="contained"
                size="large"
                className="animate__animated animate__fadeIn animate__delay-1.5s gradient-button"
                onClick={() => setOpen(true)}
                sx={{
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                {t("landing.register-button")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Register open={open} onClose={onClose} />
        <CardRequest open={openCardRequest} onClose={onClose} />
      </Container>
    </React.Fragment>
  );
}
