import * as React from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import LanguageIcon from "@mui/icons-material/Language";

import { useTranslation } from "react-i18next";

export const LanguageSelector = (props) => {
  const [t, i18n] = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleChange("es")}>
          {t("language-selector.es")}
        </MenuItem>
        <MenuItem onClick={() => handleChange("en")}>
          {t("language-selector.en")}
        </MenuItem>
      </Menu>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        sx={{
          background: props.bg
            ? props.bg
            : "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
          borderRadius: "6px",
          height: 40,
          width: 40,
          marginTop: "auto !important",
          marginBottom: "auto !important",
        }}
      >
        <LanguageIcon />
      </IconButton>
    </React.Fragment>
  );
};
