import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { showLoading } from "react-global-loading";

const URL_API = process.env.REACT_APP_URL_API || "http://localhost:4000";

const http = axios.create({
  baseURL: URL_API,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use(
  function (config) {
    showLoading(true);
    return config;
  },
  function (error) {
    showLoading(false);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    showLoading(false);
    return response;
  },
  (error) => {
    showLoading(false);
    console.log("error", error);
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem("token");
      window.location.reload();
    }
    enqueueSnackbar("Error: " + error.response.data.message, {
      variant: "error",
    });
    return error;
  }
);

export async function endpointRequest(type, route, parameters, token) {
  try {
    let request = {
      method: type || "POST",
      url: URL_API + route,
    };
    if (type !== "GET") {
      request.data = parameters;
    } else {
      request.params = parameters;
    }
    if (token) {
      request.headers = {};
      request.headers.Authorization = token;
    }
    let res = await http(request);
    return res;
  } catch (error) {
    console.log(`Error making request to ${URL_API + route}, error: ${JSON.stringify(error)}`, "error");
  }
}
