import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import Cards from "react-credit-cards";
import "react-credit-cards/lib/styles-compiled.css";

import { useAuth } from "../../Shared/Providers/Auth.provider";
import { endpointRequest } from "../../Shared/http/httpService";

import { useTranslation } from "react-i18next";

export default function NewCardModal() {
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [expiry, setExpiry] = React.useState("");
  const [cvc, setCvc] = React.useState("");
  const [code, setCode] = React.useState("");
  const [focus, setFocus] = React.useState("");
  const { token } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const submit = async () => {
    await newCard();
  };

  const newCard = async () => {
    let card = {
      number: number,
      name: name,
      cvc: cvc,
      expiry: expiry,
      code: code,
    };
    let response = await endpointRequest("POST", "/card", card, token);
    if (response.data && response.data.error === false) {
      enqueueSnackbar(t("dashboard.cards.new.success"), {
        variant: "success",
      });
      navigate("cards");
    }
  };

  return (
    <Container component="main">
      <Typography variant="h3" className="container-title">
        {t("dashboard.cards.new.title")}
      </Typography>
      <Container
        className="form"
        sx={{
          width: { xs: "100%", md: "80%" },
        }}
      >
        <Cards
          number={number}
          name={name}
          expiry={expiry}
          focused={focus}
          cvc={cvc}
        />
        <Box component="form" noValidate onSubmit={submit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                type="text"
                id="number"
                label={t("dashboard.cards.new.number")}
                inputProps={{ maxLength: 16 }}
                onChange={(e) => setNumber(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                id="name"
                label={t("dashboard.cards.new.name")}
                type="text"
                name="name"
                inputProps={{ maxLength: 18 }}
                onChange={(e) => setName(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                id="expiry"
                label={t("dashboard.cards.new.expiry")}
                name="expiry"
                inputProps={{ maxLength: 4 }}
                onChange={(e) => setExpiry(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                id="cvc"
                label={t("dashboard.cards.new.code")}
                name="cvc"
                inputProps={{ maxLength: 3 }}
                onChange={(e) => setCvc(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                id="code"
                label={t("dashboard.cards.new.activation")}
                name="code"
                inputProps={{ maxLength: 14 }}
                onChange={(e) => setCode(e.target.value)}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              className="gradient-button"
              onClick={submit}
            >
              {t("dashboard.cards.new.submit")}
            </Button>
          </Grid>
        </Box>
      </Container>
    </Container>
  );
}
