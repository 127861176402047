import * as React from "react";
import { useRef, useEffect } from "react";

import Box from "@mui/material/Box";
import Cards from "react-credit-cards";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import "react-credit-cards/lib/styles-compiled.css";
import "./cardList.css";

export default function CardList(props) {
  useEffect(() => {
    console.log(props.selected);
  }, [props]);

  return (
    <Box sx={{ display: "inline-flex" }}>
      {props.cards && props.cards.length > 0 ? (
        props.cards.map((card) => (
          <Box
            className="cardList"
            onClick={() => props.onSelected(card)}
            sx={{
              border:
                props.selected && props.selected.number === card.number
                  ? "2px solid white"
                  : "",
              borderRadius: "15px !important",
            }}
          >
            <Cards
              number={card.number}
              name={card.name}
              expiry={"**/**"}
              cvc={""}
              sx={{
                marginBottom: "15px",
              }}
            />
          </Box>
        ))
      ) : (
        <Card className="newCard" sx={{ width: "300px !important" }}>
          <CardContent
            style={{
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            No hay Tarjetas disponibles
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
