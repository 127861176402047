import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#d800d8",
      contrastText: "#fff",
    },
    secondary: {
      main: "#234cff",
      contrastText: "#fff",
    },
    background: {
      paper: "#25164f",
      default: "#080110",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        background: "linear-gradient(45deg, #7c00ec, #d800d8)",
      },
    },
  },
  shape: {
    borderRadius: 14,
  },
});

export default Theme;
