import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { enqueueSnackbar } from "notistack";

import { endpointRequest } from "../../Shared/http/httpService";
import { useAuth } from "../../Shared/Providers/Auth.provider";
import { useTranslation } from "react-i18next";

export default function NewTransfer() {
  const { token } = useAuth();
  const [balance, setBalance] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [limits, setLimits] = React.useState({ current: "0", max: "10000" });
  
  const [bankList, setBankList] = React.useState([]);
  const [bank, setBank] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  const { t } = useTranslation("common");

  useEffect(() => {
    getBankList();
    getBalance();
  }, []);

  const getLimits = async () => {
    let response = await endpointRequest("GET", "/bank/limit", {}, token);
    if (response) setLimits(response.data);
  };

  const getBalance = async () => {
    setLoading(true);
    let response = await endpointRequest("POST", "/account/balance", {}, token);
    if (response && response.data) setBalance(response.data);
    setLoading(false);
  };

  const getBankList = async () => {
    let response = await endpointRequest("GET", "/bank/list", {}, token);
    if (response && response.data && response.data.length > 1)
      setBankList(response.data);
  };

  const submit = async (event) => {
    event.preventDefault();
    const data = {};
    const formData = new FormData(event.target);
    Array.from(formData.entries()).forEach(([key, value]) => {
      data[key] = value;
    });
    formData.append("bank", bank);
    let response = await endpointRequest("POST", "/transfer", formData, token);
    if (response.data.error === false) {
      setSubmitted(true);
    } else {
      enqueueSnackbar("Error al transferir", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={submit}
      sx={{ mt: 3, width: { xs: "90%", md: "50%" } }}
    >
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12}>
          <TextField
            name="name"
            required
            fullWidth
            id="name"
            label={t("dashboard.transfers.recipient-name")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="amount"
            required
            fullWidth
            id="amount"
            label={t("dashboard.transfers.amount")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="to"
            label={t("dashboard.transfers.recipient-account")}
            name="to"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            fullWidth
            id="bank-list"
            onChange={(event, values) => setBank(values.id)}
            options={bankList}
            renderInput={(params) => <TextField {...params} label="Banco" />}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="concept"
            label={t("dashboard.transfers.concept")}
            id="concept"
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        type="submit"
        fullWidth
        className="gradient-button"
      >
        {t("dashboard.transfers.submit")}
      </Button>
    </Box>
  );
}
